export const coins: {
  [key: string]: {
    name: string;
    symbol: string;
    slug: string;
    status: boolean;
  };
} = {
  BTC: {
    name: "Bitcoin",
    symbol: "BTC",
    slug: "bitcoin",
    status: true,
  },
  ETH: {
    name: "Ethereum",
    symbol: "ETH",
    slug: "ethereum",
    status: true,
  },
  USDC: {
    name: "USD coin",
    symbol: "USDC",
    slug: "usd-coin",
    status: true,
  },
  TRX: {
    name: "Tron",
    symbol: "TRX",
    slug: "tron",
    status: true,
  },
  USDT: {
    name: "Tether",
    symbol: "USDT",
    slug: "tether",
    status: true,
  },
  BNB: {
    name: "BNB",
    symbol: "BNB",
    slug: "bnb",
    status: true,
  },
  DOGE: {
    name: "Dogecoin",
    symbol: "DOGE",
    slug: "dogecoin",
    status: true,
  },
  LTC: {
    name: "Litecoin",
    symbol: "LTC",
    slug: "litecoin",
    status: true,
  },
  DGB: {
    name: "DigiByte",
    symbol: "DGB",
    slug: "digibyte",
    status: true,
  },
  XMR: {
    name: "Monero",
    symbol: "XMR",
    slug: "monero",
    status: true,
  },
  TON: {
    name: "Toncoin",
    symbol: "TON",
    slug: "ton",
    status: true,
  },
  POL: {
    name: "Polygon",
    symbol: "POL",
    slug: "polygon",
    status: true,
  },
  BCH: {
    name: "Bitcoin cash",
    symbol: "BCH",
    slug: "bitcoin-cash",
    status: true,
  },
  BUSD: {
    name: "Binance USD",
    symbol: "BUSD",
    slug: "binance-usd",
    status: false,
  },
  SHIB: {
    name: "Shiba Inu",
    symbol: "SHIB",
    slug: "shiba-inu",
    status: true,
  },
  SOL: {
    name: "Solana",
    symbol: "SOL",
    slug: "solana",
    status: true,
  },
  NOT: {
    name: "Notcoin",
    symbol: "NOT",
    slug: "notcoin",
    status: true,
  },
  DOGS: {
    name: "DOGS",
    symbol: "DOGS",
    slug: "dogs",
    status: true,
  },
};

export const servicesFee = [
  {
    serviceName: "Payment Link",
    fee: "1.5%",
  },
  {
    serviceName: "Donation link",
    fee: "1.5%",
  },
  {
    serviceName: "Merchant (Invoice)",
    fee: "1.5%",
  },
  {
    serviceName: "Merchant (White label)",
    fee: "2%",
  },
  {
    serviceName: "Merchant (Static address)",
    fee: "2%",
  },
  {
    serviceName: "Telegram channel gateway",
    fee: "2%",
  },
];

export const coinsPageData: {
  [key: string]: {
    name: string;
    symbol: string;
    color: string;
    secondaryColor: string;
    titleColor: string;
    whatis: string;
  };
} = {
  bitcoin: {
    name: "Bitcoin",
    symbol: "BTC",
    color: "db9236",
    secondaryColor: "dbbfa0",
    titleColor: "995608",
    whatis: `
    Bitcoin (BTC) is the pioneer of cryptocurrencies, known for its decentralized nature and global acceptance. It allows for secure and transparent peer-to-peer transactions without the need for intermediaries.<br/><br/>
    Accepting Bitcoin payments with OxaPay is a strategic choice. Bitcoin's popularity and recognition make it a trusted payment method, attracting a diverse customer base. Its borderless and digital nature enables fast and efficient transactions, simplifying cross-border payments. By offering Bitcoin payment options through OxaPay, you can tap into the vast world of cryptocurrency users while optimizing your payment processes and ensuring financial stability. Integrate Bitcoin payments with OxaPay for a secure, convenient, and effective payment solution.
    
      `,
  },
  ethereum: {
    name: "Ethereum",
    symbol: "ETH",
    color: "526bd4",
    secondaryColor: "b8c4f3",
    titleColor: "3d56ce",
    whatis: `
    Ethereum (ETH) is a prominent blockchain platform known for smart contracts and decentralized applications (DApps). It offers versatility and innovation in the crypto space.<br/><br/>
    Accepting Ethereum payments with OxaPay is a forward-thinking choice. ETH's smart contract capabilities and broad adoption make it suitable for various digital services. Providing Ethereum payment options can attract users interested in blockchain innovation and streamline your payment processes while ensuring financial stability. Integrate Ethereum payments with OxaPay for versatile, secure, and efficient digital transactions.    
      `,
  },
  "usd-coin": {
    name: "USD Coin",
    symbol: "USDC",
    color: "3e78bd",
    secondaryColor: "b1c8ec",
    titleColor: "09449e",
    whatis: `
    USD Coin (USDC) is a stablecoin that's pegged to the US Dollar.The primary purpose of USDC is to offer the advantages of digital currencies, like fast and secure transactions, while maintaining a stable value.<br/><br/>
    Accepting USDC payments with OxaPay is a secure and practical choice. USDC's fixed value reduces the impact of cryptocurrency price volatility. Offering USDC payment options can attract businesses and individuals seeking stability in their digital transactions. Integrate USDC payments with OxaPay for secure, efficient, and stable payment processing.
    
      `,
  },
  tron: {
    name: "Tron",
    symbol: "TRX",
    color: "ff2424",
    secondaryColor: "eeb3b4",
    titleColor: "ad0202",
    whatis: `
    Tron (TRX) is a blockchain platform that aims to create a decentralized internet. It offers fast and cost-effective transactions, making it suitable for various digital services.<br/><br/>
    Accepting Tron payments with OxaPay is a forward-thinking choice. Tron's high throughput and low fees are advantageous for businesses and users alike. Providing Tron payment options can attract tech-savvy users and streamline your payment processes while ensuring financial stability. Integrate Tron payments with OxaPay for efficient, rapid, and cost-effective transactions.    
      `,
  },
  tether: {
    name: "Tether",
    symbol: "USDT",
    color: "29a17b",
    secondaryColor: "9ed9c5",
    titleColor: "045d3f",
    whatis: `
        Tether (USDT) is a stablecoin pegged to traditional fiat currencies like
        the US Dollar, offering stability and consistency. It's widely used for
        various transactions and provides a secure choice for businesses and
        individuals.<br/><br/>
        Accepting Tether payments with OxaPay is a smart business move. Tether's
        stability minimizes the risks of cryptocurrency price fluctuations,
        ensuring secure transactions. Swift and cost-effective, Tether is ideal
        for various payment needs, including cross-border transactions. Offering
        Tether payment options can attract a broader customer base and streamline
        payments, all while ensuring financial stability. Integrate Tether payments
        with OxaPay for a secure, stable, and efficient payment method.
      `,
  },
  bnb: {
    name: "BNB",
    symbol: "BNB",
    color: "ffcc00",
    secondaryColor: "d3c598",
    titleColor: "4d3e00",
    whatis: `
    Binance Coin (BNB) is the native cryptocurrency of the Binance exchange, one of the largest cryptocurrency platforms globally. It offers various use cases and benefits.<br/><br/>
    Accepting Binance Coin payments with OxaPay is a strategic move. BNB's utility and wide acceptance make it versatile for various payment needs. Providing Binance Coin payment options can attract cryptocurrency enthusiasts and streamline your payment processes while ensuring financial stability. Integrate Binance Coin payments with OxaPay for secure, versatile, and efficient digital transactions.    
      `,
  },
  dogecoin: {
    name: "Dogecoin",
    symbol: "DOGE",
    color: "dcc265",
    secondaryColor: "d2c697",
    titleColor: "4e4000",
    whatis: `
    Dogecoin (DOGE) started as a fun and friendly cryptocurrency but has gained popularity for its active and growing community. It provides a lighthearted yet secure way for transactions.<br/><br/>
    Accepting Dogecoin payments with OxaPay is a forward-thinking move. Dogecoin's low transaction fees and fast confirmations make it ideal for online purchases and microtransactions. Offering Dogecoin payment options can engage a unique audience and streamline your payment processes while ensuring financial stability. Integrate Dogecoin payments with OxaPay for a fun, fast, and efficient payment solution.
      `,
  },
  litecoin: {
    name: "Litecoin",
    symbol: "LTC",
    color: "2880ff",
    secondaryColor: "b3c7ed",
    titleColor: "0d40a2",
    whatis: `
    Litecoin (LTC) is a well-established cryptocurrency that shares similarities with Bitcoin. It offers fast and secure transactions, making it suitable for various payment needs.<br/><br/>
    Accepting Litecoin payments with OxaPay is a prudent choice. Litecoin's efficient blockchain technology ensures quick and low-cost transactions, reducing payment-related expenses. Providing Litecoin payment options can attract tech-savvy users and streamline your payment processes while ensuring financial stability. Integrate Litecoin payments with OxaPay for a reliable, swift, and cost-effective payment method.
    
      `,
  },
  digibyte: {
    name: "Digibyte",
    symbol: "DGB",
    color: "006ad1",
    secondaryColor: "b3c7ed",
    titleColor: "004b97",
    whatis: `
    DigiByte (DGB) is a fast and secure blockchain that prioritizes cybersecurity. It offers advanced features like DigiShield and MultiShield for enhanced protection.<br/><br/>
    Accepting DigiByte payments with OxaPay is a wise choice for security-conscious users. DGB's focus on safety and efficiency makes it a suitable option for various transactions. Providing DigiByte payment options can attract users who prioritize cybersecurity and streamline your payment processes while ensuring financial stability. Integrate DigiByte payments with OxaPay for secure, speedy, and reliable digital transactions.    
      `,
  },
  monero: {
    name: "Monero",
    symbol: "XMR",
    color: "f06724",
    secondaryColor: "e4b9a9",
    titleColor: "a53004",
    whatis: `
    Monero (XMR) is a privacy-focused cryptocurrency that places a strong emphasis on ensuring the anonymity and confidentiality of its users' transactions. It uses advanced cryptographic techniques to provide untraceable and unlinkable transactions, making it a preferred choice for individuals seeking enhanced privacy in their financial transactions.<br/><br/>
    Accepting Monero payments with OxaPay can be a valuable addition to your payment options. Monero's privacy features provide an extra layer of confidentiality, making it an excellent choice for users who prioritize discreet financial transactions. By offering Monero payment options through OxaPay, you can cater to privacy-conscious customers, expand your payment processing capabilities, and maintain financial stability. Integrate Monero payments with OxaPay for secure, private, and efficient transactions. 
      `,
  },
  ton: {
    name: "TON",
    symbol: "TON",
    color: "2164e1",
    secondaryColor: "b3c7ed",
    titleColor: "004b97",
    whatis: `
    TON (Telegram Open Network) coin is a versatile cryptocurrency designed to enhance transaction speeds and reduce costs, making it a prime choice for businesses seeking to incorporate digital payments. As a blockchain solution that emphasizes scalability and user-friendliness, TON enables businesses to process transactions swiftly and economically.
    <br/><br/>
    Accepting Toncoin payments with OxaPay can provide businesses with the opportunity to tap into this cryptocurrency's features and offer customers an additional payment option for various goods and services. With OxaPay, integrating TON payments becomes seamless, offering a streamlined, quick, and cost-effective way for businesses to handle transactions and maintain financial agility.
    `,
  },
  polygon: {
    name: "Polygon",
    symbol: "POL",
    color: "8248e4",
    secondaryColor: "cdb8f3",
    titleColor: "6d3fbd",
    whatis: `
    Polygon (POL) is a Layer-2 scaling solution that operates on the Ethereum blockchain.It enables developers to build Ethereum-compatible decentralized applications (dApps) , which means that it can process transactions faster and more cheaply than the Ethereum mainnet. This makes Polygon payments a more attractive option for businesses that want to accept cryptocurrency payments.
<br/><br/>
Accepting POL payments with OxaPay is a forward-thinking choice. POL's smart contract capability, scalability and high transaction speed and low costs benefit businesses and users. Providing POL payment options can attract tech-savvy users and streamline your payment processes while ensuring financial stability. Integrate POL payments with OxaPay for efficient, rapid, and cost-effective transactions.    
      `,
  },
  "bitcoin-cash": {
    name: "Bitcoin cash",
    symbol: "BCH",
    color: "90e448",
    secondaryColor: "b7d598",
    titleColor: "3e8622",
    whatis: `
    Bitcoin Cash (BCH) is a cryptocurrency that emerged from a Bitcoin hard fork, aiming for faster transaction times and lower fees. It maintains the fundamental principles of cryptocurrencies, offering decentralized, secure, and peer-to-peer transactions.
<br/><br/>
Accepting Bitcoin Cash payments through OxaPay can be advantageous for businesses seeking efficient and cost-effective transactions. With its enhanced scalability and faster processing times, Bitcoin Cash facilitates smoother and quicker payments. ntegrating Bitcoin Cash payments with OxaPay not only enhances the user experience by offering secure transactions but also allows your business to tap into the growing cryptocurrency user community. This integration can significantly improve your payment infrastructure, leading to increased financial agility and stability. By adopting Bitcoin Cash through OxaPay, you can streamline transaction processes and leverage the evolving landscape of digital payments.`,
  },
  "shiba-inu": {
    name: "Shiba Inu",
    symbol: "SHIB",
    color: "e30404",
    secondaryColor: "eeb4b3",
    titleColor: "df0806",
    whatis: `
    Shiba Inu (SHIB) is a cryptocurrency known for its meme culture and expansive ecosystem, including DeFi applications and a DEX. It attracts a large, engaged community with its microtransaction capabilities and decentralized approach.
<br/><br/>
Accepting Shiba Inu payments with OxaPay offers businesses a unique opportunity to connect with a youthful, digital-first audience. This integration facilitates swift, secure transactions and showcases a commitment to embracing innovative payment methods. With OxaPay, incorporating SHIB payments enhances your service offerings, ensuring a seamless, forward-thinking customer experience that leverages the popularity and agility of Shiba Inu in the crypto space.    
      `,
  },
  solana: {
    name: "Solana",
    symbol: "SOL",
    color: "6d80cf",
    secondaryColor: "b7c4f2",
    titleColor: "314793",
    whatis: `
      Solana (SOL) is a high-performance blockchain platform renowned for its exceptional speed and efficiency in processing transactions. It stands out in the cryptocurrency ecosystem for its innovative consensus mechanism and the ability to support a wide array of decentralized applications (DApps) and crypto projects.
  <br/><br/>
  Accepting Solana payments with OxaPay is a strategic move toward leveraging cutting-edge blockchain technology. SOL's rapid transaction capabilities and growing adoption make it an ideal choice for businesses looking to offer quick and reliable digital services. By integrating Solana payment options, you can cater to a tech-savvy audience eager for fast, seamless transactions and contribute to your platform's growth by enhancing user experience. Embrace Solana payments with OxaPay for high-speed, secure, and cost-effective digital payment solutions.
        `,
  },
  notcoin: {
    name: "Notcoin",
    symbol: "NOT",
    color: "f5c002",
    secondaryColor: "d3c698",
    titleColor: "b07004",
    whatis: `
    Notcoin (NOT) is a unique cryptocurrency merging gaming with blockchain via a "tap to earn" system. Originating as a Telegram game, its simple, addictive play attracted 35 million users. Now hosted on the TON blockchain, Notcoin offers secure transactions, continuing to expand its reach and user base.
    <br/><br/>
    Accepting Notcoin payments with OxaPay connects businesses to a large, dynamic community of crypto enthusiasts. Offering Notcoin as a payment option can enhance transaction speed and affordability, broaden your customer base, and drive business growth. Integrate Notcoin with OxaPay for efficient, swift, and cost-effective transactions.
    `,
  },
  dogs: {
    name: "DOGS",
    symbol: "DOGS",
    color: "535353",
    secondaryColor: "c4c4c4",
    titleColor: "#000",
    whatis: `
    DOGS (DOGS) is a popular cryptocurrency shaped by favorite meme trends and fits perfectly with the Telegram community thanks to its unique appeal. Starting as a symbol of fun and togetherness, DOGS has won over more than 50 million users. Developed on The Open Network (TON) blockchain, DOGS provides fast and secure transactions, constantly increasing its popularity and user interaction. 
    <br/><br/>
    Accepting DOGS payments with OxaPay connects your business to a large and enthusiastic crypto community, creating opportunities to grow your customer base and increase sales. Engaging with DOGS fans boosts business growth and customer loyalty. Offering DOGS as a payment option can enhance transaction speed, reduce costs and foster significant business growth. Incorporate DOGS with OxaPay for an efficient, speedy, and cost-effective payment process.
    `,
  },
};

export const pluginsPageData: {
  [key: string]: {
    name: string;
    color: string;
    shortDescription: string;
    download: string;
    link: string;
    whatis: string;
  };
} = {
  woocommerce: {
    name: "Woocommerce",
    color: "5f30bc",
    shortDescription:
      "WooCommerce is an open-source e-commerce plugin for WordPress. It is designed for small to large-sized online merchants using WordPress.",
    download: "https://oxapay.com/plugins/oxapay_woocommerce.zip",
    link: "https://docs.oxapay.com/integrations/plugins/woocommerce",
    whatis: `WooCommerce is like a special tool for websites, especially the ones made with WordPress. It's free and easy to use. You know how some websites let you buy things online? Well, WooCommerce helps regular websites do that too! It adds a magical shop feature, turning websites into online stores. So, if you want to sell stuff on your website, WooCommerce is the way to go. It makes everything simple and helps you set up your online shop without any fuss.`,
  },
  wisecp: {
    name: "WISECP",
    color: "6fc60c",
    shortDescription:
      "An all-in-one automation, billing, and support system tailored for web hosting companies, offering a user-friendly interface and extensive functionality.",
    download: "https://oxapay.com/plugins/oxapay_wisecp.zip",
    link: "https://docs.oxapay.com/integrations/plugins/wisecp",
    whatis: `An all-in-one automation, billing, and support system tailored for web hosting companies, offering a user-friendly interface and extensive functionality.`,
  },
  blesta: {
    name: "Blesta",
    color: "ff4d06",
    shortDescription:
      "A flexible and developer-friendly billing and client management solution, ideal for web hosting providers and other service-based businesses.",
    download: "https://oxapay.com/plugins/oxapay_blesta.zip",
    link: "https://docs.oxapay.com/integrations/plugins/blesta",
    whatis: `A flexible and developer-friendly billing and client management solution, ideal for web hosting providers and other service-based businesses.`,
  },
  clientexec: {
    name: "ClientExec",
    color: "2ea0d5",
    shortDescription:
      "A comprehensive billing and support platform designed for web hosting businesses, streamlining client management, invoicing, and support tickets.",
    download: "https://oxapay.com/plugins/oxapay_clientexec.zip",
    link: "https://docs.oxapay.com/integrations/plugins/clientexec",
    whatis: `A comprehensive billing and support platform designed for web hosting businesses, streamlining client management, invoicing, and support tickets.`,
  },
  whmcs: {
    name: "WHMCS",
    color: "6fc60c",
    shortDescription:
      "WHMCS is an automation platform that simplifies and automates all aspects of operating an online web hosting and domain registrar business.",
    download: "https://oxapay.com/plugins/oxapay_whmcs.zip",
    link: "https://docs.oxapay.com/integrations/plugins/whmcs",
    whatis: `WHMCS is a powerful software used by web hosting businesses to manage various aspects of their operations. It acts like a digital assistant, helping hosting providers handle tasks like customer billing, account management, and support ticketing all from one place. With WHMCS, businesses can automate routine tasks, like sending invoices and reminders to customers, saving a lot of time and effort. It also helps in setting up and managing hosting packages, domain registrations, and handling customer inquiries efficiently. Think of WHMCS as your behind-the-scenes manager, ensuring that your web hosting business runs smoothly, allowing you to focus on providing excellent service to your clients.`,
  },
  "easy-digital-downloads": {
    name: "Easy Digital Downloads",
    color: "9f9f9f",
    shortDescription:
      "Easy Digital Downloads is a complete eCommerce solution for selling digital products on your WordPress website.",
    download: "https://oxapay.com/plugins/oxapay_edd.zip",
    link: "https://docs.oxapay.com/integrations/plugins/easy-digital-downloads",
    whatis: `Easy Digital Downloads (EDD) is a user-friendly plugin designed for selling digital products on your website. Imagine it as your virtual storefront for digital goods. Whether you're selling eBooks, software, music, or any other digital content, EDD simplifies the process. It enables you to showcase your products, set prices, and securely deliver files to customers upon purchase. EDD also handles payment gateways, making transactions smooth and secure. What makes it particularly appealing is its simplicity; you don't need to be a tech expert to set up your online store. Plus, it provides detailed sales reports, helping you track your earnings effortlessly. So, if you have digital products to sell, EDD takes the hassle out of online sales, making your virtual shop a breeze to manage.`,
  },
  prestashop: {
    name: "PrestaShop",
    color: "0078d7",
    shortDescription:
      "PrestaShop is a powerful and customizable open-source eCommerce platform for building online stores.",
    download: "https://oxapay.com/plugins/oxapay_prestashop.zip",
    link: "https://docs.oxapay.com/integrations/plugins/prestashop",
    whatis: `PrestaShop is a feature-rich and flexible eCommerce platform designed to help businesses create and manage their online stores. With its open-source nature, PrestaShop offers full control and customization options, allowing store owners to tailor the platform to meet their specific needs. Whether you're selling physical products, services, or digital goods, PrestaShop supports a wide variety of product types and integrates seamlessly with multiple payment gateways, shipping methods, and marketing tools. It also provides robust analytics and reporting tools to help you monitor and grow your business. If you're looking for a scalable solution to build and manage an online store, PrestaShop is a reliable choice.`,
  },
  "paid-memberships-pro": {
    name: "Paid Memberships Pro",
    color: "6fc60c",
    shortDescription:
      "Paid Memberships Pro is a popular WordPress plugin designed for managing and selling paid memberships.",
    download: "https://oxapay.com/plugins/oxapay_pmpro.zip",
    link: "https://docs.oxapay.com/integrations/plugins/paid-memberships-pro",
    whatis: `Paid Memberships Pro is a popular WordPress plugin designed for managing and selling paid memberships. It allows site administrators to restrict access to content or services based on membership levels, offering exclusive content to paying members.`,
  },
  "gravity-forms": {
    name: "Gravity Forms",
    color: "6fc60c",
    shortDescription:
      "Gravity Forms is a powerful WordPress plugin that allows users to create advanced and customizable forms with ease.",
    download: "https://oxapay.com/plugins/oxapay_gravityforms.zip",
    link: "https://docs.oxapay.com/integrations/plugins/gravity-forms",
    whatis: `Gravity Forms is a WordPress plugin that allows site owners, admins, and developers to create simple and complex forms to collect information on their WordPress site and then decide what to do with that information.`,
  },
  "restrict-content-pro": {
    name: "Restrict Content Pro",
    color: "0073aa",
    shortDescription:
      "Restrict Content Pro is a WordPress plugin that helps you create a membership site by restricting access to content based on user subscriptions.",
    download: "https://oxapay.com/plugins/oxapay_restrictcontentpro.zip",
    link: "https://docs.oxapay.com/integrations/plugins/restrict-content-pro",
    whatis: `Restrict Content Pro is a membership and content restriction plugin for WordPress. It allows site owners to lock specific content behind paywalls or membership levels, giving them full control over who can access their premium content.`,
  },
};
